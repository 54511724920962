import dashBlack from "../../../Images/adminSideBar/dashBlack.svg"
import userBlack from "../../../Images/adminSideBar/userBlack.svg"
import vendorBlack from "../../../Images/adminSideBar/vendorBlack.svg"
import subBlack from "../../../Images/adminSideBar/subBlack.svg"
import categoryBlack from "../../../Images/adminSideBar/categoryBlack.svg"
import supportBlack from "../../../Images/adminSideBar/supportBlack.svg"
import cmsBlack from "../../../Images/adminSideBar/cmsBlack.svg"
import settingBlack from "../../../Images/adminSideBar/settingBlack.svg"
import dashColor from "../../../Images/adminSideBar/dashColor.svg"
import userColor from "../../../Images/adminSideBar/userColor.svg"
import vendorColor from "../../../Images/adminSideBar/vendorColor.svg"
import subColor from "../../../Images/adminSideBar/subColor.svg"
import categoryColor from "../../../Images/adminSideBar/categoryColor.svg"
import supportColor from "../../../Images/adminSideBar/supportColor.svg"
import cmsColor from "../../../Images/adminSideBar/cmsColor.svg"
import settingColor from "../../../Images/adminSideBar/settingColor.svg"
import text from "../../../Common/Languages/en_US.json"

export const adminSideMenu = [
    { name: text.dashboard, icon: dashBlack, link: '/admin/dashboard' ,colorIcon: dashColor},
    { name: text.totalusers, icon: userBlack, link: '/admin/totalUsers', colorIcon: userColor },
    { name: text.totalvendors, icon: vendorBlack, link: '/admin/totalVendors' , colorIcon: vendorColor},
    { name: `Subscription List`, icon: subBlack, link: '/admin/subscription' , colorIcon: subColor},
    { name: `Transaction List`, icon: subBlack, link: '/admin/transaction' , colorIcon: subColor},
    { name: `Plans`, icon: subBlack, link: '/admin/subscriptionplans', colorIcon: subColor },
    { name: text.categories, icon: categoryBlack, link: '/admin/categories' , colorIcon: categoryColor},
    { name: text.support, icon: supportBlack, link: '/admin/support' , colorIcon: supportColor},
    { name: text.cmspages, icon: cmsBlack, link: '/admin/cmspages' , colorIcon: cmsColor},
    { name: text.settings, icon: settingBlack, link: '/admin/settings' , colorIcon: settingColor},
]